/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clientJson from '../../../package.json';
import FabricVersion from '../../FabricVersion';

const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		root: {
			margin: '2%'
		},
		footer: {
			backgroundColor: dark ? '#5e558e' : '#e8e8e8',
			color: dark ? '#ffffff' : undefined,
			textAlign: 'center',
			position: 'fixed',
			left: 0,
			right: 0,
			bottom: 0
		},
		footerlogo: {
                        width: '80px',
                        cursor: 'pointer',  // Corrected
                        marginRight: '8px',  // Corrected
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                },
                displayColumn: {
                        display: 'flex',
                        backgroundColor: dark ? '#5e558e' : '#e8e8e8',
                        color: '#fff',
                        cursor: 'pointer',  // Corrected
                        textAlign: 'center',
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        bottom: '19px',
                        justifyContent: 'center',
                },
                color: {
                        color: dark ? '#fff' : '#000',
                        "&:hover": {color: dark ? '#fff' : '#000' },
                }
	};
};

const FooterView = ({ classes }) => (
	<div className={classes.root}>
		<div>
			<div className={classes.displayColumn}>
                                <a href="https://gyanbatua.ai/" className={classes.color}>
                                <img                  
                                        src="https://gyanbatua.ai/images/logo.svg"
                                        alt="Logo"
                                        className={classes.footerlogo} // Applying footerlogo styles
                                /></a>                                                    
                                          
                                <a href="https://matrix-secure.in/" className={classes.color}>
                                        {'.. 2024 Matrix Secure Applications (OPC) Pvt. Ltd. All rights reserved.'}
                                </a>                                                                
                        </div>                                 
                        <div className={classes.footer}>                                  
                                {'Hyperledger Explorer Client Version: '}                     
                                {clientJson.version}                                                               
                                &emsp;                              
                                {'Fabric Compatibility: '} {FabricVersion.map(v => v)}              
                        </div> 
		</div>
	</div>
);

export default withStyles(styles)(FooterView);
